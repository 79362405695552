import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../Components/Loader";

const LazyLoad = () => {
  const Home = lazy(() => import("../Pages/Home"));
  const Category = lazy(() => import("../Pages/Category"));
  const Singleproducts = lazy(() => import("../Pages/Singleproducts"));
  const Contact = lazy(() => import("../Pages/Contact"));
  const About = lazy(() => import("../Pages/About"));
  const Faqs = lazy(() => import("../Pages/Faqs"));
  const TermsConditions = lazy(() => import("../Pages/TermsConditions"));
  const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
  const Cart = lazy(() => import("../Pages/Cart"));
  const Orders = lazy(() => import("../Pages/Orders"));
  const Transaction = lazy(() => import("../Pages/Transaction"));
  const SearchResult = lazy(() => import("../Pages/SearchResult"));
  const TrackOrder = lazy(() => import("../Pages/TrackOrder"));
  const AllTestimonials = lazy(
    () => import("../Components/Reviews/AllTestimonials")
  );

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/category" element={<Category />} /> */}
          <Route path="category/:categoryName" element={<Category />} />
          {/* <Route path="/single-product" element={<Singleproducts />} /> */}
          <Route path="product/:productHandle" element={<Singleproducts />} />
          <Route path="search/:word" element={<SearchResult />} />

          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />

          <Route path="cart" element={<Cart />} />
          <Route path="orders/:orderId" element={<Orders />} />
          <Route
            path="transaction/:transactionStatus"
            element={<Transaction />}
          />
          <Route path="testimonials" element={<AllTestimonials />} />
          <Route path="track-order" element={<TrackOrder />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default LazyLoad;
